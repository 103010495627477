import {
  EXTEND_ID,
  EXTEND_ENVIRONMENT,
} from '@/services/Configuration'

const ExtendWarranty: React.FC = () => (
  <>
    <script
      src="https://sdk.helloextend.com/extend-sdk-client-bigcommerce-addon/v1/extend-sdk-client-bigcommerce-addon.min.js"
    />
    <script
      src="https://sdk.helloextend.com/extend-sdk-client/v1/extend-sdk-client.min.js"
    />
    <script
      dangerouslySetInnerHTML={{
        __html: `Extend.config({ storeId: "${EXTEND_ID}", environment: "${EXTEND_ENVIRONMENT}" })`,
      }}
    />
  </>
)

export default ExtendWarranty
